import React, { Fragment, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Home = React.lazy(() => import("./pages/home"));
const Book = React.lazy(() => import("./pages/Book"));
const Awards = React.lazy(() => import("./pages/Awards"));
const Recipes = React.lazy(() => import("./pages/Recipes"));
const FoodDet = React.lazy(() => import("./pages/FoodDet"));

const App = (props) => {
  return (
    <Fragment>
      <Suspense fallback={<div className="spinner" />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/BookAndTv" element={<Book />} />
          <Route path="/Awards" element={<Awards />} />
          <Route path="/Recipes" element={<Recipes />} />
          <Route path="/Recipes/:RecipesId" element={<FoodDet />} />
        </Routes>
      </Suspense>
    </Fragment>
  );
};

export default App;
